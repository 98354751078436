import React, { createContext, useEffect, useState } from "react";
import { Tokens, chains, metadata } from "./config/config";
import { toast } from "react-toastify";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import IERC20 from "./abis/tokenAbi.json";
import FIXED from "./abis/contractFixed.json";
import FLEXIBLE from "./abis/contractFlexibale.json";
import CROSSFIXED from "./abis/contractCrossFixed.json";
import CROSSFLEXIBLE from "./abis/contractCrossFlexibale.json";
import { Contract, ethers } from "ethers";

export const appContext = createContext();

const projectId = process.env.REACT_APP_PROJECT_ID;

try {
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: chains,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    // defaultChain: ,
  });
} catch (err) {}

function AppContext({ children }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open, close } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const [provider, setProvider] = useState("");
  const [signer, setSigner] = useState("");

  const setWalletProvider = async () => {
    try {
      if (walletProvider) {
        const etherProvider = new ethers.providers.Web3Provider(walletProvider);
        const etherSigner = await etherProvider.getSigner();
        setProvider(etherProvider);
        setSigner(etherSigner);
      } else {
        const etherProvider = new ethers.providers.JsonRpcProvider(chains[0].rpcUrl);
        setProvider(etherProvider);
        setSigner(null);
      }
    } catch (err) {
      // console.log("error in setWalletProvider : ", err);
    }
  };

  const toastSuccess = (text, time = 3000) => {
    toast.success(text, {
      autoClose: time,
    });
  };
  const toastError = (text, time = 3000) => {
    toast.error(text, {
      autoClose: time,
    });
  };

  const getTokenContract = (address) => {
    try {
      if (signer) {
        const contract = new Contract(address, IERC20, signer);
        return contract;
      } else {
        const contract = new Contract(address, IERC20, provider);
        return contract;
      }
    } catch (err) {
      // console.log("error =>>> : ", err);
    }
  };

  const checkAllowane = async (token, userAddres, contractAddress) => {
    try {
      const contract = getTokenContract(token?.address);
      const allowancewei = await contract.allowance(userAddres, contractAddress);

      const allowance = ethers.utils.formatEther(allowancewei);
      return allowance;
    } catch (err) {
      // console.log("error in checkAllowane:", err);
      return false;
    }
  };

  const giveAllowance = async (tokenAddress, contractAddress) => {
    try {
      const contract = getTokenContract(tokenAddress);
      const totalSupply = await contract.totalSupply();
      // const tx = await contract.approve(contractAddress, totalSupply);
      const tx = await contract.approve(contractAddress, totalSupply);
      const receipt = await tx.wait();
      if (receipt?.transactionHash) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  const getContractFixed = () => {
    try {
      if (signer) {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_FIXED, FIXED, signer);
        return contract;
      } else {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_FIXED, FIXED, provider);
        return contract;
      }
    } catch {
      return false;
    }
  };

  const getContractFlexible = () => {
    try {
      if (signer) {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_FLEXIBALE, FLEXIBLE, signer);
        return contract;
      } else {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_FLEXIBALE, FLEXIBLE, provider);
        return contract;
      }
    } catch {
      return false;
    }
  };
  const getContractCrossFixed = () => {
    try {
      if (signer) {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_CROSS_FIXED, CROSSFIXED, signer);
        return contract;
      } else {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_CROSS_FIXED, CROSSFIXED, provider);
        return contract;
      }
    } catch {
      return false;
    }
  };

  const getContractCrossFlexible = () => {
    try {
      if (signer) {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE, CROSSFLEXIBLE, signer);
        return contract;
      } else {
        const contract = new Contract(process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE, CROSSFLEXIBLE, provider);
        return contract;
      }
    } catch {
      return false;
    }
  };

  const getTokenBalance = async (token) => {
    try {
      const contract = getTokenContract(token?.address);
      const balanceWei = await contract.balanceOf(address);
      return ethers.utils.formatEther(balanceWei);
    } catch (error) {
      // console.log("error in getTokenBalance : ", error);
      return "0";
    }
  };

  const getTokenBalanceForAddress = async (token, useraddress) => {
    try {
      const contract = getTokenContract(token?.address);
      const balanceWei = await contract.balanceOf(useraddress);
      return ethers.utils.formatEther(balanceWei);
    } catch (error) {
      // console.log("error in getTokenBalance : ", error);
      return "0";
    }
  };

  const getPancakeRouter = () => {
    try {
      const abi = ["function getAmountsOut(uint amountIn, address[] calldata path) external view returns (uint[] memory amounts)"];
      const contract = new Contract(process.env.REACT_APP_ROUTER, abi, provider);
      return contract;
    } catch (err) {
      // console.log("error in getPancakeRouter : ", err);
    }
  };

  const getUSDTAmount = async (token0, amountIn) => {
    try {
      if (Number(amountIn) > 0) {
        const router = await getPancakeRouter();
        const amountOutWei = await router.getAmountsOut(amountIn, [token0, process.env.REACT_APP_USDT_TOKEN]);
        return ethers.utils.formatEther(amountOutWei[1]);
      } else {
        return "0";
      }
    } catch (err) {
      // console.log("error in getPancakeRouter : ", err);
      return "0";
    }
  };

  useEffect(() => {
    setWalletProvider();
  }, [walletProvider]);

  const connectWallet = () => {
    try {
      open();
    } catch (err) {
      // console.log("error in connectWallet : ", err);
    }
  };

  return (
    <appContext.Provider
      value={{
        connectWallet,
        address,
        chainId,
        isConnected,
        disconnect,
        toastSuccess,
        toastError,
        getTokenContract,
        checkAllowane,
        giveAllowance,
        getContractFixed,
        getContractFlexible,
        getTokenBalance,
        provider,
        getPancakeRouter,
        getUSDTAmount,
        getContractCrossFixed,
        getContractCrossFlexible,
        getTokenBalanceForAddress,
      }}
    >
      {children}
    </appContext.Provider>
  );
}

export default AppContext;
